import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import Header from 'modules/Header';
import NavBar from 'modules/navigation/NavBar';
import Login from 'modules/login/LoginScreen';
import Home from 'modules/Home';
import PageNotFound from 'modules/PageNotFound';
import AllSurgeons from 'modules/surgeonLists/AllSurgeons';
import VerifiedSurgeons from 'modules/surgeonLists/VerifiedSurgeons';
import BlockedSurgeons from 'modules/surgeonLists/BlockedSurgeons';
import AllCases from 'modules/caseLists/AllCases.jsx';
import ReportedCases from 'modules/caseLists/ReportedCases';
import ReportedComments from 'modules/ReportedComments';
import Feedback from 'modules/Feedback';
import Notification from 'modules/Notification';
import CreateAdmin from 'modules/CreateAdmin';
import ListAdmin from 'modules/ListAdmin';
import ReportedUsers from 'modules/ReportedUsers';
import LoginCallbackScreen from 'modules/login/LoginCallbackScreen';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  return (
    <Router>
      <div id="macro-layout">
        <Header />
        <div id="navbar-and-content">
          {
            isAuthenticated ?
              <>
                <NavBar />
                <Routes>
                  <Route index element={<Home />} />
                  <Route path="/all-surgeons" element={<AllSurgeons />} />
                  <Route path="/verified-surgeons" element={<VerifiedSurgeons />} />
                  <Route path="/blocked-surgeons" element={<BlockedSurgeons />} />
                  <Route path='/all-cases' element={<AllCases />} />
                  <Route path='/reported-cases' element={<ReportedCases />} />
                  <Route path='/reported-comments' element={<ReportedComments />} />
                  <Route path='/feedback' element={<Feedback />} />
                  <Route path='/push-notification' element={<Notification />} />
                  <Route path='/add-admin' element={<CreateAdmin />} />
                  <Route path='/list-admin' element={<ListAdmin />} />
                  <Route path='/reported-users' element={<ReportedUsers />} />
                </Routes>
              </>
              :
              <Routes>
                <Route index element={<Login />} />
                <Route path='/login' element={<Login />} />
                <Route path='/login/callback' element={<LoginCallbackScreen />} />
              </Routes>
          }
        </div>
      </div>
    </Router>
  );
}

export default App;
