
import { Table } from 'react-bootstrap';

import View from 'modules/View';

/**
 *
 * @param {*} props
 * @param {string} props.title
 * @param {Array<string>} props.breadcrumbs
 * @param {Array} cases List of cases to be rendered.
 * @returns
 */
const CaseList = props => {
  return (
    <View title={props.title} breadcrumbs={props.breadcrumbs}>
      <Table striped border hover>
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Case ID</th>
            <th>createdAt</th>
            <th>Image</th>
            <th>Name</th>
            <th>Case File</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>TODO</td>
            <td>TODO</td>
            <td>TODO</td>
            <td>TODO</td>
            <td>TODO</td>
            <td>TODO</td>
            <td>TODO</td>
          </tr>
        </tbody>
      </Table>
    </View>
  );
}

export default CaseList;