import React, { useState } from 'react';
import View from 'modules/View'
import axios from 'axios'

const Notification = () => {

  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationBody, setNotificationBody] = useState('');
  const [emails, setEmails] = useState([]);
  const [error, setError] = useState(null);
  const [callInProgress, setCallInProgress] = useState(false);

  const handleSendNotification = async () => {
    setError(null);
    setCallInProgress(true)
    try {
      const apiEndpoint = 'https://app1.chime.exac.com:3003/api/v1/admin/push_notification';
      const accessToken = localStorage.getItem('access_token')

      console.log(accessToken)

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Request-ID': 'AdminPanel',
        },
      };

      const data = {
        title: notificationTitle,
        body: notificationBody,
        emails: emails,
      };

      axios
        .post(apiEndpoint, data, config)
        .catch((err) => {
          if (err?.response) {
            setError(err?.response.data.message);
          } else {
            setError(`An error occurred while sending the notification. \n\n${JSON.stringify(err)} ${err}`);
          }
          return
        })
        .finally(() => {
          setCallInProgress(false)
        })
      setError(null);
    } catch (error) {
      if (error?.response) {
        setError(error.response.data.message);
      } else {
        setError('An error occurred while sending the notification.');
      }
      setCallInProgress(false)
    }
  };

  return (
    <View className="container" title="Send Push Notification">
      {
        error &&
        <div className="alert alert-danger">
          {error}
        </div>
      }
      {
        callInProgress &&
        <h5>
          Sending notification...
        </h5>
      }
      <div className="mb-3">
        <label htmlFor="notificationTitle" className="form-label">
          Notification Title
        </label>
        <input
          type="text"
          className="form-control"
          id="notificationTitle"
          value={notificationTitle}
          onChange={(e) => setNotificationTitle(e.target.value)}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="notificationBody" className="form-label">
          Notification Body
        </label>
        <textarea
          className="form-control"
          id="notificationBody"
          rows="3"
          value={notificationBody}
          onChange={(e) => setNotificationBody(e.target.value)}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="emails" className="form-label">
          Emails (comma-separated)
        </label>
        <input
          type="text"
          className="form-control"
          id="emails"
          value={emails.join(',')}
          onChange={(e) => setEmails(e.target.value.split(','))}
        />
      </div>
      <button className="btn btn-primary" onClick={handleSendNotification}>
        Send
      </button>
    </View>
  );
};

export default Notification;
