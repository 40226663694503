var OktaAuth = require('@okta/okta-auth-js').OktaAuth;

const config = {
  clientId: "0oa2vnjq0rU57Bzmy4h7",
  issuer: "https://ai-exac.okta.com/oauth2/aus2uaa191DsKphbl4h7",
  redirectUri: 'https://admin.chime.exac.com/login/callback',
  scopes: ['openid', 'profile', 'email', 'offline_access'],
  pkce: true,
}

export const authClient = new OktaAuth(config)