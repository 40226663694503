import View from 'modules/View';

const Home = () => {

  return (
    <View title="Home">
      Hello, world!
    </View>
  );

}

export default Home;