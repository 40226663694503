
import CaseList from 'modules/caseLists/CaseList';

const ReportedCases = () => {

  const cases = [];

  return (
    <CaseList
      title="Reported Cases"
      breadcrumbs={['Chime', 'Cases', 'Reported Cases']}
      cases={cases}
    />
  );

};

export default ReportedCases;