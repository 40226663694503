import View from 'modules/View';

import { Table } from 'react-bootstrap';

/**
 * Base component used for the vario types of surgeon lists
 * @param {*} props
 * @param {string} props.title
 * @param {Array<string>} props.breadcrumps
 * @param {Array} props.surgeons List of surgeon objects to render in the table
 */
const SurgeonList = props => {
  return (
    <View title={props.title} breadcrumbs={props.breadcrumbs}>
      <Table striped border hover>
        <thead>
          <tr>
            <th>Surgeon Number</th>
            <th>Image</th>
            <th>Name</th>
            <th>Email</th>
            <th>Login Type</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>TODO</td>
            <td>TODO</td>
            <td>TODO</td>
            <td>TODO</td>
            <td>TODO</td>
            <td>TODO</td>
          </tr>
          <tr>
            <td>TODO</td>
            <td>TODO</td>
            <td>TODO</td>
            <td>TODO</td>
            <td>TODO</td>
            <td>TODO</td>
          </tr>
          <tr>
            <td>TODO</td>
            <td>TODO</td>
            <td>TODO</td>
            <td>TODO</td>
            <td>TODO</td>
            <td>TODO</td>
          </tr>
        </tbody>
      </Table>
    </View>
  );
}

export default SurgeonList;