
import { useEffect, useRef, useContext } from 'react';
import { authClient } from './AuthServer';

const LoginScreen = () => {

  authClient
    .token
    .getWithRedirect({
      responseType: ['token', 'id_token'],
      state: 'defaultrandomstring',
    })
    .catch((err) => {
      console.error('Error OKTA login redirect', err);
    })

  return (<></>);
}

export default LoginScreen;
