import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router';
import decodeToken from 'jwt-decode';

import { faFile, faUserMd, faBan, faGavel, faCommentSlash, faCommentAlt, faBell, faIdBadge, faUser } from '@fortawesome/free-solid-svg-icons';

import './style.css';
import { useEffect, useState } from 'react';

const NavBar = () => {

  const [expirationString, setExpirationString] = useState('')

  useEffect(() => {
    // Update the expiration string every second (1000 milliseconds)
    const intervalId = setInterval(() => {
      setExpirationString(getSessionExpiryString());
    }, 1000);

    // Clean up the interval when the component is unmounted
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const navigate = useNavigate();

  const onClick = e => {
    e.preventDefault();
    navigate(e.target.pathname);
  }

  const logout = () => {
    localStorage.clear();
    window.location.reload();
  }

  function getSessionExpiryString() {

    const token = localStorage.getItem('access_token');

    if (!token) return 'JWT not found in localStorage';

    const decodedBody = decodeToken(token);

    const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
    const expiration = decodedBody["exp"] || null;

    const remainingSeconds = expiration - currentTimestampInSeconds;
    const remainingMinutes = Math.floor(remainingSeconds / 60);

    return `Session Expiring in ${remainingMinutes} Minutes`;
  }

  return (
    <nav id="navbar">
      <ol style={{ marginTop: 50 }}>
        <li>
          <span className="nav-link">
            <FontAwesomeIcon icon={faUserMd} />
            &nbsp;&nbsp;
            Surgeons
          </span>
          <ol>
            <li>
              <a href="/all-surgeons" onClick={onClick}>
                All Surgeons
              </a>
            </li>
            <li>
              <a href="/verified-surgeons" onClick={onClick}>
                Verified Surgeons
              </a>
            </li>
            <li>
              <a href="/blocked-surgeons" onClick={onClick}>
                Blocked Surgeons
              </a>
            </li>
          </ol>
        </li>

        <li>
          <a className="nav-link" href="/all-cases" onClick={onClick}>
            <FontAwesomeIcon icon={faFile} />
            &nbsp;&nbsp;
            All Cases
          </a>
        </li>

        <li>
          <a className="nav-link" href="/reported-cases" onClick={onClick}>
            <FontAwesomeIcon icon={faGavel} />
            &nbsp;&nbsp;
            Reported Cases
          </a>
        </li>

        <li>
          <a className="nav-link" href="/reported-comments" onClick={onClick}>
            <FontAwesomeIcon icon={faCommentSlash} />
            &nbsp;&nbsp;
            Reported Comments
          </a>
        </li>

        <li>
          <a className="nav-link" href="/feedback" onClick={onClick}>
            <FontAwesomeIcon icon={faCommentAlt} />
            &nbsp;&nbsp;
            Feedback
          </a>
        </li>

        <li>
          <a className="nav-link" href="/push-notification" onClick={onClick}>
            <FontAwesomeIcon icon={faBell} />
            &nbsp;&nbsp;
            Push Notification
          </a>
        </li>

        <li>
          <span className="nav-link">
            <FontAwesomeIcon icon={faIdBadge} />
            &nbsp;&nbsp;
            Admin
          </span>
          <ol>
            <li>
              <a href="/add-admin" onClick={onClick}>
                Add Admin
              </a>
            </li>
            <li>
              <a href="/list-admin" onClick={onClick}>
                List Admin
              </a>
            </li>
          </ol>
        </li>

        <li>
          <a className="nav-link" href="/reported-users" onClick={onClick}>
            <FontAwesomeIcon icon={faUser} />
            &nbsp;&nbsp;
            Reported Users
          </a>
        </li>

        <li>
          <a className="nav-link" href="/" onClick={logout}>
            <FontAwesomeIcon icon={faUser} />
            &nbsp;&nbsp;
            Logout
          </a>
        </li>

        <li>
          <a className="nav-link">
            &nbsp;&nbsp;
            {expirationString}
          </a>
        </li>

      </ol>
    </nav>
  );

};

export default NavBar;