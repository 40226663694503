import './style.css';

import ChimeLogo from 'assets/images/ChimeIcon.svg'

const Header = () => {
  return (
    <header id="header" style={{ height: 80 }}>
      <div style={{ display: 'flex', flexDirection: 'row', padding: '11px 20px', height: '100%', alignItems: 'center' }}>
        <img
          src={ChimeLogo}
          style={{
            width: 'auto',
            height: '80%'
          }}
        />
        &nbsp;
        &nbsp;
        <span
          style={{
            color: '#2d67b2',
            fontSize: '30px',
            fontWeight: 'bold'
          }}
        >
          Chime
        </span>
      </div>
    </header>
  )
};

export default Header;