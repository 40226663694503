import { Table } from 'react-bootstrap';

import View from 'modules/View';

const ReportedUsers = () => {

  const rows = [
    {
      surgeonNumber: 1234,
      reportId: 1234,
      reportedUserProfileImage: 'adsf',
      reportedUser: 'dfs',
      reporterProfileImage: 'adsf',
      reporter: 'asdf',
      reportedOn: new Date().toISOString()
    }
  ];

  return (
    <View
      title="Reported Users"
      breadcrums={['Chime', 'Reported Users']}
    >
      <Table>
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Report ID</th>
            <th>Reported User Profile Image</th>
            <th>Reported User</th>
            <th>Reporter Profile Image</th>
            <th>Reporter</th>
            <th>Reported On</th>
          </tr>
        </thead>
        <tbody>
          {
            rows.map((row, index) => (
              <tr key={`row-${index}`}>
                <td>{row.surgeonNumber}</td>
                <td>{row.reportId}</td>
                <td>{row.reportedUserProfileImage}</td>
                <td>{row.reportedUser}</td>
                <td>{row.reporterProfileImage}</td>
                <td>{row.reporter}</td>
                <td>{row.reportedOn}</td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </View>
  );

};

export default ReportedUsers;