
import CaseList from 'modules/caseLists/CaseList';

const AllCases = () => {

  const cases = [];

  return (
    <CaseList
      title="All Cases"
      breadcrumbs={['Chime', 'Cases', 'All Cases']}
      cases={cases}
    />
  );

};

export default AllCases;