
import './style.css';

/**
 * This is the base view (analogous to a base class) used by all all pages.
 * @param {*} props
 * @param {string} props.title Title of this view
 * @param {Array<string>} props.breadcrumbs Breadcrumb trail stored as an array
 * @returns
 */
const View = (props) => {

  const title = props.title || 'Default Title';
  const breadcrumbs = props.breadcrumbs || [];

  return <div id="content">

    <div style={{ padding: '15px' }}>
      <h1>{title}</h1>
      <hr />
      <p>
        {breadcrumbs.map((breadcrumb, index) => (
          <>
            {breadcrumb}&nbsp;
            {index < breadcrumbs.length - 1 && <>&gt;&nbsp;</>}
          </>
        ))}
      </p>

      {props.children}

    </div>

    <footer id="content-footer">
      Copyright © 2022 Exactech. All rights reserved.
    </footer>

  </div>
}

export default View;