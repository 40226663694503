import SurgeonList from 'modules/surgeonLists/SurgeonList.jsx';

const BlockedSurgeons = () => {

  const surgeons = []

  return (
    <SurgeonList
      title="Blocked Surgeons"
      breadcrumbs={['Chime', 'Surgeons', 'Blocked Surgeons']}
      surgeons={surgeons}
    />
  );

}

export default BlockedSurgeons;