import { Form, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import View from 'modules/View';

const CreateAdmin = () => {

  const initialValues = {
    adminName: '',
    adminEmail: ''
  }

  const validationSchema = Yup.object().shape({
    adminName: Yup.string().required('This field is required'),
    adminEmail: Yup.string().email('Invalid email address').required('This field is required'),
  });

  const onSubmit = values => {
    alert(JSON.stringify(values))
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  return (
    <View title="Add Admin" breadcrumbs={['Chime', 'Admin', 'Add Admin']}>
      <Form onSubmit={formik.handleSubmit}>
        <Form.Group>
          <Form.Label htmlFor="adminName">
            Name
          </Form.Label>
          {formik.touched.adminName && formik.errors.adminName && <p className="text-danger">{formik.errors.adminName}</p>}
          <Form.Control
            name="adminName"

            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.adminName}
            placeholder="Dante Alighieri"
          >
          </Form.Control>
        </Form.Group>
        <br />

        <Form.Group>
          <Form.Label htmlFor="adminEmail">Email</Form.Label>
          {formik.touched.adminEmail && formik.errors.adminEmail && <p className="text-danger">{formik.errors.adminEmail}</p>}
          <Form.Control
            name="adminEmail"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.adminEmail}
            placeholder="dante@inferno.net"
          >

          </Form.Control>
        </Form.Group>
        <br />

        <Button type='submit'>
          Create Admin
        </Button>

      </Form>
    </View>
  );

};

export default CreateAdmin;