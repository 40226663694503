import View from 'modules/View';

import { Table, Button } from 'react-bootstrap';

const Feedback = () => {

  const feedback = [
    {
      surgeonNumber: 1234,
      name: 'Name here',
      image: 'Image here',
      email: 'cow@cow.jp',
      feedback: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsum eaque quod animi veniam sunt nisi sint repudiandae odit illo nobis, distinctio iusto dolore, amet reprehenderit. Dolores culpa maiores corporis eligendi.',
      time: new Date().toISOString(),
    }
  ];

  return (
    <View title="All Feedback" breadcrumbs={['Chime', 'Feedback', 'All Feedback']}>
      <Table striped border hover>
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Name</th>
            <th>Image</th>
            <th>Email</th>
            <th>Feedback</th>
            <th>Time</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {
            feedback.map((feedback, index) => (
              <tr key={`feedback-${index}`}>
                <th>{feedback.surgeonNumber}</th>
                <th>{feedback.name}</th>
                <th>{feedback.image}</th>
                <th>{feedback.email}</th>
                <th>{feedback.feedback}</th>
                <th>{feedback.time}</th>
                <th>
                  <Button variant='primary'>Action</Button>
                </th>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </View>
  );
}

export default Feedback;