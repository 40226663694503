import SurgeonList from 'modules/surgeonLists/SurgeonList.jsx';

const AllSurgeons = () => {

  const surgeons = []

  return (
    <SurgeonList
      title="All Surgeons"
      breadcrumbs={['Chime', 'Surgeons', 'All Surgeons']}
      surgeons={surgeons}
    />
  );

}

export default AllSurgeons;