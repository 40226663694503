import View from 'modules/View';

import { Table, Button } from 'react-bootstrap';

const ListAdmin = () => {

  const admins = [
    {
      surgeonNumber: 1234,
      name: 'Dante Alighieri',
      email: 'danet@inferno.net',
      adminSince: new Date().toISOString(),
      status: 'Active',
    }
  ];

  return (
    <View name="List Admin" breadcrumbs={['Chime', 'Admin', 'ListAdmin']}>
      <Table>
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Admin Name</th>
            <th>Admin Email</th>
            <th>Admin Since</th>
            <th>Admin Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {
            admins.map((admin, index) => (
              <tr key={`admin-${index}`}>
                <td>{admin.surgeonNumber}</td>
                <td>{admin.name}</td>
                <td>{admin.email}</td>
                <td>{admin.adminSince}</td>
                <td>{admin.status}</td>
                <td>
                  <Button>
                    Action
                  </Button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </View>
  );

};

export default ListAdmin;