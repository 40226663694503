import { Table, Button } from 'react-bootstrap';

import View from 'modules/View';

const ReportedComments = () => {

  const comments = [
    {
      surgeonNumber: '1234',
      comment: 'Comment here',
      reportedBy: 'Dr. Reporter',
      reportedOn: '10/10/2022',
      numberOfReports: 100,
      reportTime: new Date().toISOString(),
    }
  ];

  return (
    <View title="Reported Comments" breadcrumbs={['Chime', 'Comments', 'Reported Comments']}>
      <Table>
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Comment</th>
            <th>Reported By</th>
            <th>No. of Report</th>
            <th>Report Time</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {
            comments.map((comment, index) => (
              <tr key={`comment-${index}`}>
                <td>{comment.surgeonNumber}</td>
                <td>{comment.comment}</td>
                <td>{comment.reportedBy}</td>
                <td>{comment.numberOfReports}</td>
                <td>{comment.reportTime}</td>
                <td>
                  <Button variant='danger'>
                    Remove
                  </Button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </View>
  );

};

export default ReportedComments;