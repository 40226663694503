import SurgeonList from 'modules/surgeonLists/SurgeonList.jsx';

const VerifiedSurgeons = () => {

  const surgeons = []

  return (
    <SurgeonList
      title="Verified Surgeons"
      breadcrumbs={['Chime', 'Surgeons', 'Verified Surgeons']}
      surgeons={surgeons}
    />
  );

}

export default VerifiedSurgeons;