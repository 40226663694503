import { useEffect, useRef, useContext } from 'react';
import { authClient } from './AuthServer';

const LoginCallbackScreen = () => {

  useEffect(() => {
    authClient
      .token
      .parseFromUrl()
      .then(async function (res) {
        const tokens = res.tokens
        localStorage.setItem('access_token', tokens.accessToken.accessToken)
        window.location.reload()
      })
      .catch(function (err) {
        console.error('Error OKTA set token', err)
      })
  }, []);

  return (<></>);
}

export default LoginCallbackScreen;
